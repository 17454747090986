import { FILTERS } from '../core/actionNames';
import { getFilters, getRestaurantsList } from '../core/api';
import { setData as setKeywordsData } from './keywords.actions';
import { filtersModelSelector } from '../selectors/filters.selectors';
import { getFiltersValues } from '../core/filters.helpers';
import { setData as setTagsData } from './tags.actions';
import {
    // reFetchData as reFetchByoData,
    reactOnFiltersLoaded as reactByoOnFiltersLoaded
} from './byo.actions';
import {
    reFetchData as reFetchTopTrendsData,
} from './topTrends.actions';

const { SET_DATA, RESET_DATA, SET_FIELD } = FILTERS;

export function setField(name, value) {
    return {
        type: SET_FIELD,
        name,
        value
    };
}

export function setData(data) {
    return {
        type: SET_DATA,
        data,
    };
}

function updateCurrentPage() {
    return (dispatch, getState) => {
        if (getState().byo.isLoaded) {
            dispatch(reactByoOnFiltersLoaded());
        }
        if (getState().topTrends.isLoaded) {
            dispatch(reFetchTopTrendsData());
        }
    }
}

export function resetData() {
    return {
        type: RESET_DATA
    };
}

export function reset() {
    return (dispatch, getState) => {
        dispatch(
            setData({
                active: null,
                activeRestaurantListId: getState().filters
                    .defaultRestaurantListId,
            })
        );
        dispatch(
            setData({
                active: getFiltersValues(filtersModelSelector(getState())),
            })
        );
        dispatch(updateCurrentPage());
    };
}

export function apply(data) {
    return (dispatch) => {
        dispatch(setData({ active: data, }));
        dispatch(updateCurrentPage());
    }
}

export function updateActiveRestaurantList() {
    return (dispatch, getState) => {
        getRestaurantsList(getState().countries.current, getState().filters.activeRestaurantListId).then((data) => {
            dispatch(setData({ activeRestaurantList: data }));
        });
    }
}

export function setActiveRestaurantList(id) {
    return (dispatch, getState) => {
        dispatch(setData({
            activeRestaurantListId: id,
            active: null,
        }));
        dispatch(setData({
            active: getFiltersValues(filtersModelSelector(getState())),
        }));
        dispatch(updateCurrentPage());
        dispatch(setData({ activeRestaurantList: [] }));
        dispatch(updateActiveRestaurantList());
    }
}

export function setCustomRestaurantLists(data) {
    return (dispatch, getState) => {
        dispatch(setData({
            customRestaurantLists: data,
        }));
    }
}
let request = {
    abort: () => {
    },
    then: () => {
    },
};

export function get() {
    return (dispatch, getState) => {
        const currentCountry = getState().countries.current;
        if (!currentCountry) return;

        if ( request ) {
            request.abort()
        }

        request = getFilters(currentCountry);

        request
            .then((data) => {
                const keywords = data.words.map(keyword => ({
                    ...keyword,
                    wordExtensions: keyword.wordExtensions || []
                }));

                const tags = keywords
                    .filter(i => i.isTag)
                    .map(i => ({
                        id: i.id,
                        title: i.name,
                        categoryId: i.tagCategoryId
                    }));

                tags.sort((a, b) => {
                    const aTitle = a.title.toLowerCase();
                    const bTitle = b.title.toLowerCase();
                    return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
                });

                const { tagCategories = [] } = data;

                tagCategories.sort((a, b) =>{
                    const aOrder = a.order;
                    const bOrder = a.order;

                    return aOrder - bOrder;
                });

                dispatch(setTagsData({
                    items: tags,
                    categories: tagCategories.map(i => ({
                        id: i.id,
                        title: i.name,
                    })),
                }));
                dispatch(setKeywordsData(keywords));
                dispatch(setData({
                    tooltipsData: data.tooltips,
                    raw: data.filters,
                    isDataLoaded: true,
                    restaurantLists: data.restaurantLists.filter(({isCustom}) => !isCustom).map((el) => ({
                        ...el,
                        isActive: el.id === data.defaultRestaurantListId,
                    })),
                    customRestaurantLists: [...getState().filters.customRestaurantLists],
                    defaultRestaurantListId: data.defaultRestaurantListId,
                }));
                if (!getState().activeRestaurantListId) {
                    dispatch(setData({
                        activeRestaurantListId: data.defaultRestaurantListId,
                    }));
                    if (getState().filters.activeToApply) {
                        dispatch(setData({
                            active: getState().filters.activeToApply,
                            activeToApply: null,
                        }));
                    } else {
                        dispatch(setData({
                            active: getFiltersValues(filtersModelSelector(getState())),
                        }));
                    }
                }
                dispatch(updateActiveRestaurantList());
                dispatch(updateCurrentPage());
            });
    }
}